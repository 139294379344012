svg.horse {
  background: #e6227a;
  padding: 8px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0%;
  width: 52px;
}

a.colorful:hover {
  background-position: left bottom;
}

body {
  background: linear-gradient(-45deg, #ddd7df, #bfafc4, #e6e2e7);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.navbar {
  box-shadow: 0px 0px 6px #3b012f81;
}

.nextscreen {
  box-shadow: 6px -6px 15px rgba(0, 0, 0, 0.55);
}

.footer {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.907);
  background-color: #171515;
}

.discord:hover {
  fill: #8c9eff;
}

.twitter:hover {
  fill: #009ded;
}

.github:hover {
  fill: #171515;
}

.telegram-app:hover {
  fill: #1c93e3;
}

.icons8:hover {
  fill: #3ddab4;
}

.metamask:hover {
  fill: #f4841a;
}

#etherscan1:hover {
  fill: #21325b;
}

#etherscan2:hover {
  fill: #979695;
}

a.colorful {
  border: 0;
  border-radius: 50px;
  padding: 15px 20px 16px;
  text-transform: uppercase;
  background: linear-gradient(to right, #fa68b0 50%, #5a3466 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 1s ease;
  box-shadow: 0px 0px 6px rgba(49, 49, 49, 0.507);
}

.btn-copy {
  @apply flex cursor-pointer items-center justify-center rounded-full border-2 border-dpink-700 bg-transparent py-2 px-2 text-center font-audio  font-bold uppercase tracking-wider text-dpurp-100 shadow-lg transition-all delay-100 duration-500 ease-out hover:-translate-y-1 hover:scale-110 hover:border-bpink-100 hover:bg-bpink-100;
}

#ts-particles {
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 0;
  position: absolute;
}

#tsparticles-nftsection {
  height: 58%;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 0;
  position: absolute;
}

.nft-parent:hover > .nft-title {
  opacity: 100%;
  transition: .5s;
}

.address-hider {
  background-image: linear-gradient(270deg, #18171f 22%, rgba(24, 23, 31, 0));
  width: 50%;
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.article-section p {
  margin-top: 50px;
}

.article-section h2 {
  margin-top: 50px;
  font-size: 125%;
  font-weight: bold;
  text-align: center;
}

.article-section li {
  list-style-type: circle;
  margin-top: 10px;
  padding-left: 15px;
}

.wallet-connect-btn {
  background-color: #513b6d;
}

.wallet-connect-btn:hover {
  background-color: #6a518a;
}

.coming-soon {
  color:#b181c0 
}
